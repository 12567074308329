import React, { useState, useEffect, useContext } from 'react';
import InputMask from "react-input-mask";
import { Link, navigate } from 'gatsby';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { AppContext } from '@context'
import { Api } from '@components/utility/Api';
import Preloader from '@components/structure/Preloader';

//MUI
import {
	Box,
	TextField,
	Grid,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
} from '@mui/material';
import { checkValiation } from '@components/utility/Validation';
import { StaticImage } from 'gatsby-plugin-image';

const NarcolepsyRegistration = () => {
	const [getFields, setFields] = useState(() => []);
	const [webinars, setWebinars] = useState(() => []);
	const [loading, setLoading] = useState(() => false);
	const [medicationType, setMedicationType] = useState({
		xywav: false,
		xyrem: false,
		other: false,
		none: false
	});
	let form = '';

	const {selectedWebinars, setSelectedWebinars} = useContext(AppContext);


	const handleSubmit = (e) => {
		e.preventDefault();
		form = e.target;
		const inputs = form.querySelectorAll('input');
		const headingErrorEl = form.querySelector('.heading-error') || null;
		let fieldValid = true;
		let formValid = true;
		let UUIDs = [];

		inputs.forEach(function (element) {
			const elementID = element.name;
			fieldValid = checkValiation(form, elementID, element.type, getFields);

			if (!fieldValid && headingErrorEl) {
				formValid = false;
			}
		});

		if(!formValid) {
			headingErrorEl.classList.add('show-error');
			headingErrorEl.focus();
		}
		else {
			headingErrorEl.classList.remove('show-error');
			setLoading(true);

			let medication = [];

			if(getFields['XYWAV-oral-solution']) {
				medication.push('XYWAV');
			}
			if(getFields['XYREM-oral-solution']) {
				medication.push('XYREM');
			}
			if(getFields['none']) {
				medication.push('None');
			}
			if(getFields['other']) {
				medication.push('Other');
			}

			webinars.map((item, index) => {
				UUIDs.push(item[1].UUID)
			});

			// const registerData = {
			// 	"eventid": UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0],
			// 	"firstname": getFields['first-name'],
			// 	"lastname": getFields['last-name'],
			// 	"email": getFields['email-address'],
			// 	"phone":  getFields['phone-number'],
			// 	"zipcode":  getFields['zip-code'],
			// 	"reminderpref": getFields['text-reminder-group'],
			// 	"iam": getFields['narcolepsy-patient-caregiver-group'],
			// 	"diagnosedwith": getFields['narcolepsy-interested-group'],
			// 	"treatment": (JSON.stringify(medication).replace(/\"|\]|\[/g, "")).toLowerCase(),
			// 	"multipleauthorization": "true"
			// }

			// const success = response => {
			// 	if(response.success) {
			// 		navigate("/xywav-narcolepsy/confirmation")
			// 	}
			// 	else {
			// 		console.log(response);
			// 		setLoading(false);
			// 	}
			// }
			// const fail = error => {
			// 	setLoading(false);
			// 	console.log('Internal Error ', error);
			// }

			// Api.post_register(success, fail, registerData, 2);

			sendDataJazzCX(UUIDs, medication);
		}
	};

	const sendDataJazzCX = (UUIDs, medication) => {

		let indicationType = '';

		if(getFields['narcolepsy-interested-group'] === 'narcolepsywithc') {
			indicationType = 'Type 1 Narcolepsy (with Cataplexy)';
		}
		else if(getFields['narcolepsy-interested-group'] === 'narcolepsywoc') {
			indicationType = 'Type 2 Narcolepsy (without Cataplexy)';
		}
		else {
			indicationType = 'Uncertain';
		}


		const registerDataJazzCX = {
			"eventId": UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0],
			"firstName": getFields['first-name'],
			"lastName": getFields['last-name'],
			"email": getFields['email-address'],
			"zipCode":  getFields['zip-code'],
			"phoneNumber":  getFields['phone-number'],
			"optInEmail": "Yes",
			"optInSms": getFields['text-reminder-group'] === 'yes' ? 'Yes' : 'No',
			"patientTypeQuestion": getFields['narcolepsy-patient-caregiver-group'] === 'patient' ? 'Patient' : 'Caregiver',
			"indicationType": indicationType,
			"medication": medication,
			"dataSource": "JWPS-BRANDED-NARCOLEPSY"
		}

		const success = response => {
			if(response.success) {
				navigate("/xywav-narcolepsy/confirmation");
			}
			else {
				console.log(response);
			}
			setLoading(false);
		}
		const fail = error => {
			setLoading(false);
			console.log('Internal Error ', error);
		}

		Api.post_register_CX(success, fail, registerDataJazzCX);
	}

	const handleChange = (e) => {
		let fields = getFields;

		if(e.target.name === 'XYWAV-oral-solution' || e.target.name === 'XYREM-oral-solution' || e.target.name === 'other' || e.target.name === 'none') {

			if(e.target.name === 'XYWAV-oral-solution') {
				setMedicationType({
					xywav: medicationType.xywav ? false : true,
					xyrem: false,
					none: false,
					other: false,
				});
				
				getFields['XYWAV-oral-solution'] = medicationType.xywav ? false : true;
				getFields['XYREM-oral-solution'] = false;
				getFields['other'] = false;
				getFields['none'] = false;
			}
			if(e.target.name === 'XYREM-oral-solution') {
				setMedicationType({
					xyrem: medicationType.xyrem ? false : true,
					xywav: false,
					none: false,
					other: false,
				});

				getFields['XYWAV-oral-solution'] = false;
				getFields['XYREM-oral-solution'] = medicationType.xyrem ? false : true;
				getFields['other'] = false;
				getFields['none'] = false;
			}
			if(e.target.name === 'other') {
				setMedicationType({
					other: medicationType.other ? false : true,
					xywav: false,
					xyrem: false,
					none: false,
				});

				getFields['XYWAV-oral-solution'] = false;
				getFields['XYREM-oral-solution'] = false;
				getFields['other'] = medicationType.other ? false : true;
				getFields['none'] = false;
			}
			if(e.target.name === 'none') {
				setMedicationType({
					none: medicationType.none ? false : true,
					xywav: false,
					xyrem: false,
					other: false,
				});

				getFields['XYWAV-oral-solution'] =  false;
				getFields['XYREM-oral-solution'] =  false;
				getFields['other'] = false;
				getFields['none'] = medicationType.none ? false : true;
			}
		}
		else {
			fields[e.target.name] =
				e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		}

		setFields(fields);

		if (form) {
			checkValiation(form, e.target.name, e.target.type, getFields);
		}
	};

	const removeClicked = (e, key) => {
		e.preventDefault();
		let newData = [...webinars];

		newData = newData.filter((item, index) => index !== key)
		setWebinars(newData);
		setSelectedWebinars(Object.fromEntries(newData));
		redirectCheck(newData);
	}

	const redirectCheck = (data) => {
		if (!data.length) {
			navigate("/xywav-narcolepsy");
		}
	}

	useEffect(() => {
		const isEDMode = window.location.href.indexOf('ed=1') > -1;
		if(isEDMode) {
			//screenshot
			const success = response => {
				let tempData = {};
				tempData[0] = {
						Date: response[0].Sessions[0].Date,
						ShortDescription: response[0].ShortDescription,
						Topic: response[0].Topic,
						UUID: response[0].Sessions[0].UUID,
				}
				setWebinars(Object.entries(tempData));
			}
			const fail = error => {
				console.log('Internal Error ', error);
			}
			Api.get_webinar(success, fail, "branded", "brandednarc");
		}
		else {
			redirectCheck(Object.entries(selectedWebinars));
			setWebinars(Object.entries(selectedWebinars));
		}
	}, []);

	return (
		<Layout pageType='narcolepsy-branded' className='narcolepsyBranded no-tabs' pixelCode='Start Registration'>
			<Seo title='Narcolepsy Treatment Registration | Jazz Webinars' description="If you're living with or caring for someone with narcolepsy, register today to learn more about a treatment option, gain access to the upcoming narcolepsy live webinars and educational programs." />

			<div>
				{/* <StaticImage
					className='test-desktop'
					src='../../../static/images/narcolepsy-branded-hero.png'
					alt='Narcolepsy Hero Banner Desktop on register page'
				/> */}
				<img
					className='test-desktop'
					src='/images/BRANDED_Narcolepsy_photoshop_compression.jpg'
					alt='Narcolepsy Hero Banner Desktop on home page'
				/>
				<div className='narco-branded-hero-mobile'></div>
				<section className='container firstSection'>
					<Grid container>
						<Grid item xs={12}>
							<span className='back-link'>
								&#8249; <Link to='/xywav-narcolepsy'>Back to Webinar Library</Link>
							</span>
							<div className='lv-copy'>
								<h1>
									Learn about XYWAV
									from experts in sleep
									medicine
								</h1>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>

			<section className='container narcolepsyBranded'>
				<div>
					<p className='header centerText'>
						To continue, tell us a bit about yourself
					</p>
					<div className='divider'></div>
					<p className='copy centerText'>
						To register for your live webinars, please complete the form
						below.<span className='desktopOnlyContent'> You can view, edit, or remove the webinars you’ve selected
						in the right-hand column.</span>
					</p>
				</div>

				<div className='selectWebinarSection formRight spaceTop'>
					<p className='label upperCase'>Your Selected Webinars</p>
					{webinars ? webinars.map((item, key) => (
					<div className='selectedWebinarItem' key={key}>
						<a href="#" onClick={(e) => {
							removeClicked(e, key);
						}}>Remove</a>
						<Link to='/xywav-narcolepsy'>Edit</Link>
						<p className='header2' dangerouslySetInnerHTML={{__html: item[1].Topic.replace(/\®/g, "<sup style='position: absolute; margin-top:-2px; font-size:16px'>&reg;</sup>")}}/>
						<p className='body2'>{item[1].ShortDescription || 'No description provided'}</p>
						<hr className='divider' />
						<p className='timeDate'>TIME &amp; DATE</p>
						<p className='date'>{item[1].Date}</p>
					</div>
					)) : null}
				</div>
				<form
					name='registration'
					method='post'
					onSubmit={handleSubmit}>
					<p className='error-label heading-error' tabIndex='-1'>
						<strong>
							Please be sure to complete or correct the required field(s).
						</strong>
					</p>
					<p className='fieldsRequired'>
						All fields are required unless otherwise specified.
					</p>
					<Grid container justifyContent={'space-between'}>
						<Grid item xs={12} className='formLeft spaceTop'>
							<Box
								className='formLeftWidth'
								sx={{
									'& > :not(style)': { m: 1, width: '100%' },
								}}
								noValidate
								autoComplete='off'>
								<label htmlFor='first-name'>First Name</label>
								<p className='error-label first-name'>
									Please enter your First Name.
								</p>
								<TextField
									id='first-name'
									name='first-name'
									variant='outlined'
									color='primary'
									inputProps={{ maxLength: 50 }}
									onChange={handleChange}
								/>
								<label htmlFor='last-name'>Last Name</label>
								<p className='error-label last-name'>
									Please enter your Last Name.
								</p>
								<TextField
									id='last-name'
									name='last-name'
									variant='outlined'
									color='primary'
									inputProps={{ maxLength: 50 }}
									onChange={handleChange}
								/>
								<label htmlFor='email-address'>Email Address</label>
								<p className='error-label email-address'>
									Please enter a valid Email Address.
								</p>
								<TextField
									id='email-address'
									name='email-address'
									variant='outlined'
									color='primary'
									inputProps={{ maxLength: 50 }}
									onChange={handleChange}
								/>
								<label htmlFor='phone-number'>Mobile Phone Number</label>
								<p className='error-label phone-number'>
									Please enter a valid Mobile Phone Number.
								</p>
								<TextField
									id='phone-number'
									name='phone-number'
									variant='outlined'
									color='primary'
									inputProps={{ maxLength: 10 }}
									onChange={handleChange}
								/>
								<label htmlFor='zip-code'>ZIP Code</label>
								<p className='error-label zip-code'>
									Please enter a valid ZIP Code.
								</p>
								<InputMask
									mask="99999"
									onChange={handleChange}
									maskChar=""
								>
									{() => <TextField
										id='zip-code'
										name='zip-code'
										variant='outlined'
										color='primary'
									/>}
								</InputMask>
							</Box>
							<div className='formLeftWidth'>
								<p id='text-reminder'>
									<strong>Would you like a text reminder?</strong>
									<br />
									Messaging and data rates may apply. Reply STOP to
									98637 to cancel and/or HELP for help. Up to 10 msg/mo.{' '}
									<a href='https://www.xywav.com/sms-terms-and-conditions/' target={"_blank"}>SMS Terms &amp; Conditions</a>
								</p>
								<p className='error-label radio-padding-fix text-reminder-group'>
									Please select an option.
								</p>
								<RadioGroup
									row
									onChange={handleChange}
									aria-labelledby='text-reminder'
									name='text-reminder-group'>
									<FormControlLabel
										value='yes'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Yes'
									/>
									<FormControlLabel
										value='no'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='No'
									/>
								</RadioGroup>
							</div>

							<div className='formLeftWidth'>
								<p className='bold' id='narcolepsy-patient-caregiver'>
									Are you a narcolepsy patient or caregiver?
								</p>
								<p className='error-label radio-padding-fix narcolepsy-patient-caregiver-group'>
									Please select an option.
								</p>
								<RadioGroup
									row
									onChange={handleChange}
									aria-labelledby='narcolepsy-patient-caregiver'
									name='narcolepsy-patient-caregiver-group'>
									<FormControlLabel
										value='patient'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Patient'
									/>
									<FormControlLabel
										value='caregiver'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Caregiver'
									/>
								</RadioGroup>
							</div>

							<div className=''>
								<p className='bold' id='narcolepsy-interested'>
									What type of narcolepsy are you interested in?
								</p>
								<p className='error-label radio-padding-fix narcolepsy-interested-group'>
									Please select an option.
								</p>
								<RadioGroup
									onChange={handleChange}
									name='narcolepsy-interested-group'
									aria-labelledby='narcolepsy-interested'>
									<FormControlLabel
										value='narcolepsywithc'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Type 1 Narcolepsy (with Cataplexy)'
									/>
									<FormControlLabel
										value='narcolepsywoc'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Type 2 Narcolepsy (without Cataplexy)'
									/>
									<FormControlLabel
										value='uncertain'
										control={
											<Radio
												color='primary'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label='Uncertain'
									/>
								</RadioGroup>
							</div>

							<div className=''>
								<p className='bold' id='medications-taking'>
									Which of the following medications are you taking for
									your cataplexy and/or excessive daytime sleepiness in
									narcolepsy?
								</p>
								<p className='error-label radio-padding-fix medication-taking-group'>
									Please select an option.
								</p>
								<FormControl
									required
									component='fieldset'
									sx={{ m: 3 }}
									variant='standard'
									className='checkboxGroup'
									onChange={handleChange}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													name='XYWAV-oral-solution'
													checked = {medicationType.xywav}
													id='medication-XYWAV'
													color='primary'
													aria-labelledby='medications-taking'
													sx={{
														'& .MuiSvgIcon-root':
															{
																fontSize: 32,
															},
													}}
												/>
											}
											label={<>XYWAV<sup className="sub-reg">&reg;</sup> (calcium, magnesium, potassium, and sodium oxybates) oral solution</>}
										/>
										<FormControlLabel
											control={
												<Checkbox
													name='XYREM-oral-solution'
													checked = {medicationType.xyrem}
													id='medication-XYREM'
													color='primary'
													aria-labelledby='medications-taking'
													sx={{
														'& .MuiSvgIcon-root':
															{
																fontSize: 32,
															},
													}}
												/>
											}
											label={<>XYREM<sup className="sub-reg">&reg;</sup> (sodium oxybate) oral solution</>}
										/>
										<FormControlLabel
											control={
												<Checkbox
													name='other'
													checked = {medicationType.other}
													id='medication-other'
													color='primary'
													aria-labelledby='medications-taking'
													sx={{
														'& .MuiSvgIcon-root':
															{
																fontSize: 32,
															},
													}}
												/>
											}
											label='Other'
										/>
										<FormControlLabel
											control={
												<Checkbox
													name='none'
													checked = {medicationType.none}
													color='primary'
													id='medication-none'
													aria-labelledby='medications-taking'
													sx={{
														'& .MuiSvgIcon-root':
															{
																fontSize: 32,
															},
													}}
												/>
											}
											label='None'
										/>
										<div className='consentCheckboxContainer'>
											<FormControlLabel
												control={
													<Checkbox
														name='consent'
														color='primary'
														className='consentCheckbox'
													/>
												}
												label=''
												sx={{
													'& .MuiSvgIcon-root':
														{
															fontSize: 32,
														},
												}}
											/>
											<p className='consent-copy'>
												By checking this box, you confirm that you
												are 18 years of age or older and a
												resident of the US. You understand that
												Jazz Pharmaceuticals and its business
												partners will collect the personal
												information you provide and use it to
												manage your enrollment in the program. You
												are indicating that you would like to
												receive information from Jazz
												Pharmaceuticals about educational
												programs, products, and services. To learn
												how Jazz Pharmaceuticals will use and
												protect your personal information, please
												review our{' '}
												<a href='https://www.jazzpharma.com/privacy-statement/' target="_blank">Privacy Statement</a>. You
												can opt out at any time.
											</p>
											<p className='error-label consent'>
												Please check the box above to continue.
											</p>
										</div>
									</FormGroup>
								</FormControl>
							</div>

							<div className='registerButton'>
								{!loading ? 
									<CtaButton
										title='REGISTER'
										class='cta main-cta registerButtonItem'
										type='submit'
										disabled={loading}
									/>
								: <Preloader/> }
							</div>
						</Grid>
					</Grid>
				</form>
			</section>
		</Layout>
	);
};

export default NarcolepsyRegistration;